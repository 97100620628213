import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  ErrorBar,
} from "recharts";
import styles from "../../styles/dashboard.module.css";

function BarChartComponent({ dashboardData }) {
  const { t } = useTranslation();
  const [showStdDev, setShowStdDev] = useState(true);
  const [activeBars, setActiveBars] = useState({
    personalAverage: true,
    teamAverage: true,
    companyAverage: true,
    globalAverage: true,
  });

  useEffect(() => {
    console.log(dashboardData);
  }, [dashboardData]);
    

  if (!dashboardData) {
    return (
      <div className={styles.divPibito}>
        <img className={styles.pibito} src="/img/Pibito.png" alt="No data" />
        <p className={styles.noData}>{t("noData")}</p>
      </div>
    );
  }

  // Alternar la visibilidad de las barras
  const handleLegendClick = (dataKey) => {
    setActiveBars((prev) => ({
      ...prev,
      [dataKey]: !prev[dataKey],
    }));
  };

  const data = [
    {
      name: t("Goals"),
      personalAverage: dashboardData.mediaPersonal?.Goals || 0,
      personalStdDev: dashboardData.desviacionPersonal?.Goals || 0,
      teamAverage: dashboardData.mediaEquipo?.Goals || 0,
      teamStdDev: dashboardData.desviacionEquipo?.Goals || 0,
      companyAverage: dashboardData.mediaEquipoEmpresa?.Goals || 0,
      companyStdDev: dashboardData.desviacionEquipoEmpresa?.Goals || 0,
      globalAverage: dashboardData.mediaEquipoGlobal?.Goals || 0,
    },
    {
      name: t("Roles"),
      personalAverage: dashboardData.mediaPersonal?.Roles || 0,
      personalStdDev: dashboardData.desviacionPersonal?.Roles || 0,
      teamAverage: dashboardData.mediaEquipo?.Roles || 0,
      teamStdDev: dashboardData.desviacionEquipo?.Roles || 0,
      companyAverage: dashboardData.mediaEquipoEmpresa?.Roles || 0,
      companyStdDev: dashboardData.desviacionEquipoEmpresa?.Roles || 0,
      globalAverage: dashboardData.mediaEquipoGlobal?.Roles || 0,
    },
    {
      name: t("Processes"),
      personalAverage: dashboardData.mediaPersonal?.Processes || 0,
      personalStdDev: dashboardData.desviacionPersonal?.Processes || 0,
      teamAverage: dashboardData.mediaEquipo?.Processes || 0,
      teamStdDev: dashboardData.desviacionEquipo?.Processes || 0,
      companyAverage: dashboardData.mediaEquipoEmpresa?.Processes || 0,
      companyStdDev: dashboardData.desviacionEquipoEmpresa?.Processes || 0,
      globalAverage: dashboardData.mediaEquipoGlobal?.Processes || 0,
    },
    {
      name: t("Interactions"),
      personalAverage: dashboardData.mediaPersonal?.Interactions || 0,
      personalStdDev: dashboardData.desviacionPersonal?.Interactions || 0,
      teamAverage: dashboardData.mediaEquipo?.Interactions || 0,
      teamStdDev: dashboardData.desviacionEquipo?.Interactions || 0,
      companyAverage: dashboardData.mediaEquipoEmpresa?.Interactions || 0,
      companyStdDev: dashboardData.desviacionEquipoEmpresa?.Interactions || 0,
      globalAverage: dashboardData.mediaEquipoGlobal?.Interactions || 0,
    },
  ];

  return (
    <div className={styles.divInteriorGrafico}>
      <button onClick={() => setShowStdDev(!showStdDev)} className={styles.btnStdDev}>{showStdDev ? t("hideDesviation") : t("showDesviation")}</button>
      <ResponsiveContainer width="80%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 15, right: 20, left: 0, bottom: 0 }}
        barCategoryGap="0%" // Elimina la separación entre categorías
        barGap={0} // Elimina la separación entre las barras del mismo grupo
      >
        <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: 14, fontFamily: "Inter, sans-serif", fill: "#e3147f" }}
          />
          <YAxis
            domain={[0, 4]}
            ticks={[0, 1, 2, 3, 4]}
            tick={{ fontSize: 14, fontFamily: "Inter, sans-serif", fill: "#e3147f" }}
          />
          <Tooltip />

          {/* Renderizar barras dinámicamente según el estado */}
          {activeBars.personalAverage && (
            <Bar
              dataKey="personalAverage"
              fill="#4C87B2"
              name={t("Media Personal")}
              barSize={30}
              radius={[5, 5, 0, 0]}
            >
              {showStdDev && (
                <ErrorBar dataKey="personalStdDev" width={4} stroke="black" direction="y" />
              )}
              <LabelList
                dataKey="personalAverage"
                position="top"
                formatter={(value) => value.toFixed(1)}
                style={{ fill: "#4C87B2", fontWeight: "bold", fontSize: 12 }}
              />
            </Bar>
          )}
          {activeBars.teamAverage && (
            <Bar
              dataKey="teamAverage"
              fill="#48BAA5"
              name={t("Media Equipo")}
              barSize={30}
              radius={[5, 5, 0, 0]}
            >
              {showStdDev && (
              <ErrorBar dataKey="teamStdDev" width={4} stroke="black" direction="y" />
              )}
              <LabelList
                dataKey="teamAverage"
                position="top"
                formatter={(value) => value.toFixed(1)}
                style={{ fill: "#48BAA5", fontWeight: "bold", fontSize: 12 }}
              />
            </Bar>
          )}
          {activeBars.companyAverage && (
            <Bar
              dataKey="companyAverage"
              fill="#595959"
              name={t("Media Empresa")}
              barSize={30}
              radius={[5, 5, 0, 0]}
            >
              {showStdDev && (
              <ErrorBar dataKey="companyStdDev" width={4} stroke="black" direction="y" />
              )}
              <LabelList
                dataKey="companyAverage"
                position="top"
                formatter={(value) => value.toFixed(1)}
                style={{ fill: "#595959", fontWeight: "bold", fontSize: 12 }}
              />
            </Bar>
          )}
          {activeBars.globalAverage && (
            <Bar
              dataKey="globalAverage"
              fill="#e3147f"
              name={t("Media Benchmark")}
              barSize={30}
              radius={[5, 5, 0, 0]}
            >
              <LabelList
                dataKey="globalAverage"
                position="top"
                formatter={(value) => value.toFixed(1)}
                style={{ fill: "#e3147f", fontWeight: "bold", fontSize: 12 }}
              />
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>

      {/* Leyenda personalizada */}
      <CustomLegend activeBars={activeBars} onLegendClick={handleLegendClick} />
    </div>
  );
}

function CustomLegend({ payload, activeBars, onLegendClick }) {
  const legendItems = [
    { dataKey: "personalAverage", color: "#4C87B2", label: "Media Personal" },
    { dataKey: "teamAverage", color: "#48BAA5", label: "Media Equipo" },
    { dataKey: "companyAverage", color: "#595959", label: "Media Empresa" },
    { dataKey: "globalAverage", color: "#e3147f", label: "Media Benchmark" },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {legendItems.map((item) => (
        <div
          key={item.dataKey}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            opacity: activeBars[item.dataKey] ? 1 : 0.5, // Indica visualmente si está activa
          }}
          onClick={() => onLegendClick(item.dataKey)}
        >
          <div
            style={{
              width: "40px",
              height: "15px",
              backgroundColor: item.color,
              borderRadius: "2px",
            }}
          ></div>
          <span style={{ fontSize: "14px", color: "#595959" }}>{item.label}</span>
        </div>
      ))}
    </div>
  );
}

export default BarChartComponent;
